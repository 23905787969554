import { Divider, Link } from "@adobe/react-spectrum";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import PluginsLayout from "../../components/PluginsLayout";
import "../../styles/carousel.css";
import PluginInfoCard from "../../components/PluginInfoCard";
import { PluginContext } from "../../contexts/PluginContext";
import { ThemeContext } from "../../contexts/ThemeContext"
import SEO from "../../components/seo";
import SchemaORG from "../../data/canvas-schema.json";
import pluginsArray from "../../data/plugins.json"

const WellContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  /* position: relative; */
  width: 100%;
  text-align: center;

  .plugin-banner {
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  @media (min-width: 900px) {
    font-size: var(--spectrum-global-dimension-font-size-400) !important;
    .banner-well {
      box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.2),
        0 1px 0 rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      max-width: 900px;
      margin: 0 auto;
      background-color: var(--spectrum-global-color-gray-75);
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }
`;

// const VideoContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
//   .responsive-iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//   }
// `;

const CanvasMasterPlugin = () => {
  const { CanvasBanner, CarouselFiles, CanvasPluginDark, CanvasPluginLight } =
    useStaticQuery(
      graphql`
        query {
          CanvasBanner: file(
            relativePath: { eq: "highlights/canvasmaster-highlight2.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }

          CanvasPluginDark: file(
            relativePath: { eq: "canvas-panel/canvas-dark.png" }
          ) {
            publicURL
          }
          CanvasPluginLight: file(
            relativePath: { eq: "canvas-panel/canvas-light.png" }
          ) {
            publicURL
          }

          CarouselFiles: allFile(
            filter: { relativeDirectory: { eq: "canvas-carousel" } }
          ) {
            nodes {
              id
              childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      `
    )

  const { activePlugin, setActivePlugin } = useContext(PluginContext);
  const { colorScheme } = useContext(ThemeContext)

  useEffect(() => {
    if (activePlugin !== "canvas-master") setActivePlugin("canvas-master")
  });

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: `0`,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const pluginInfoCardSettings = {
    logo: "Canvas-SVG",
    logoColor: "var(--spectrum-global-color-blue-400)",
    title: "Canvas Master",
    version: pluginsArray.filter(plugin => plugin.title === "Canvas Master")[0]
      .version,
    price: "26.00",
    link: "https://adobe.com/go/cc_plugins_discover_plugin?pluginId=5bb1b709&workflow=share",
  }
  return (
    <PluginsLayout>
      <SEO
        title="Canvas Master for Photoshop"
        description="Canvas Master, plugin for Photoshop"
        image="https://www.cc-extensions.com/img/twitter-canvas.jpg"
        slug="plugins/canvas-master"
        schema={SchemaORG}
      />
      <WellContainer>
        <div className="banner-well">
          <h1 className="plugin">Canvas Master</h1>
          <h2
            className="plugin"
            style={{ color: `var(--spectrum-global-color-blue-400)` }}
          >
            Resize, Frame and Crop Marks
          </h2>
          <Img
            fluid={CanvasBanner.childImageSharp.fluid}
            className="plugin-banner"
          />
        </div>
      </WellContainer>

      <div style={{ textAlign: `left`, maxWidth: `900px` }}>
        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          In short
        </h3>
        <Divider size="M" />
        <p>
          Step into the realm of refined image formatting with CanvasMaster!
          <ul>
            <li>
              Adjust your image dimensions with ease in the Fit section,
              featuring an auto-rotate option for optimal results.
            </li>
            <li>
              Within the Canvas section, choose from a variety of standard sizes
              or define your custom dimensions.
            </li>
            <li>
              For further personalisation, use the Margins section to specify
              the borders.
            </li>
            <li>
              The Crop Marks section offers the ability to add corner guides,
              configurable in length, thickness, and colour.
            </li>
            <li>
              Choose your measurement preference: centimetres, millimetres, or
              inches.
            </li>
            <li>
              Press Process to duplicate and flatten your image, preparing it
              for printing.
            </li>
          </ul>
          An efficient solution to ready multiple images for grid printing on
          paper rolls - cost-effective and fast, particularly useful for
          creating colour proofs or portfolio prints.
        </p>
        <div
          style={{
            display: `grid`,
          }}
        >
          {/* https://react-slick.neostack.com/docs/example/resizable */}
          <Slider {...carouselSettings}>
            {CarouselFiles.nodes.map(({ childImageSharp, id }) => (
              <div key={id}>
                <Img
                  fluid={childImageSharp.fluid}
                  style={{ borderRadius: `6px` }}
                />
              </div>
            ))}
          </Slider>
        </div>

        <p style={{ fontSize: `80%`, textAlign: `right` }}>
          {" "}
          Images by Marcus Silva, Andrea Piacquadio, Italo Melo, Caio Mantovani
          from{" "}
          <Link>
            <a href="https://www.pexels.com">Pexels</a>
          </Link>
        </p>
        <Divider size="M" marginTop="size-500" marginBottom="size-300" />

        <img
          src={
            colorScheme === "dark"
              ? CanvasPluginDark.publicURL
              : CanvasPluginLight.publicURL
          }
          alt="Description of the image"
          style={{
            maxWidth: "80%",
            height: "auto",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        {/* <Img fluid={CanvasPlugin.childImageSharp.fluid} /> */}

        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          Buy the Plugin
        </h3>
        <Divider size="M" />
        <p>
          Try Canvas Master: with your purchase you&rsquo;re helping me (paying
          for my teenage daughter's studies, and) developing more plugins, thank
          you!
        </p>
        <PluginInfoCard
          style={{ margin: `50px auto` }}
          pluginInfo={pluginInfoCardSettings}
        />
      </div>
    </PluginsLayout>
  )
};
export default CanvasMasterPlugin;

// Image by <a href="https://pixabay.com/users/pezibear-526143/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1034121">Pezibear</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1034121">Pixabay</a>
